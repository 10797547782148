/** @jsxImportSource theme-ui */
import React from 'react'
import { Container, Flex } from "@theme-ui/components"
import Header from "./Header";

export default function Layout(props) {
    return (
        <React.Fragment>
            <img
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 0.3
                }}
                src="/images/web1.png"
                alt={''}
            />
            <img
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    opacity: 0.3
                }}
                src="/images/web2.png"
                alt={''}
            />

            <Flex
                sx={{
                    flexDirection: "column",
                    background: (theme) => theme.colors?.backgroundGradient
                }}
            >
                <Header />

                <Flex
                    sx={{
                        position: "relative",
                        backgroundSize: "100%",
                        minHeight: "84vh"
                    }}
                >
                    <Container sx={{
                      maxWidth: "120rem"
                    }}>
                        <Flex
                            sx={{
                                backgroundSize: "auto",
                                minHeight: "100%",
                                flexDirection: "column",
                                padding: "1.6rem",
                                borderColor: "background2",
                                "@media(minWidth: 768px)": {
                                    padding: "3.2rem 1.6rem"
                                }
                            }}
                        >
                            <img
                                sx={{
                                    position: "absolute",
                                    zIndex: -1,
                                    left: 0,
                                    bottom: 0
                                }}
                                src="/images/bg1.png"
                                alt={''}
                            />

                            { props.children }

                            <img
                                sx={{
                                    position: "absolute",
                                    zIndex: -1,
                                    right: 0,
                                    bottom: 0,
                                    transform: "matrix(-1, 0, 0, 1, 0, 0)"
                                }}
                                src="/images/bg1.png"
                                alt={''}
                            />
                        </Flex>
                    </Container>
                </Flex>
            </Flex>
        </React.Fragment>
    )
}
