import Pusher from "pusher-js";

let pusher: Pusher

export function getPusher(): Pusher {
  if (!pusher) {
    pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER
    });
  }
  return pusher
}
