import {Draw} from "./types/draw";

export function serverUrl() {
  return process.env.REACT_APP_SERVER_URL
}

export function portalWallet() {
  return process.env.REACT_APP_PORTAL_WALLET
}

export function shortAddress(address: string) {
  return `${address.substring(0, 5)}...${address.substring(address.length-5)}`
}

export function outcomeStatus(draw: Draw) {
  if (['drawn', 'sent', 'closed'].includes(draw?.status!)) {
    switch (draw.reward_code) {
      case "burn":
        return "Lost"
      case "back":
        return "Returned"
      case "back_and_token":
        return `Returned + ${draw.reward_token_name}`
      case "back_and_sol":
        return `Returned + ${draw.reward_amount} SOL`
    }
  }
  return ""
}