/** @jsxImportSource theme-ui */
import React, {PropsWithChildren, useEffect, useState} from 'react'
import { Flex, Spinner, Text} from "theme-ui"
import Layout from "../components/layout/Layout"
import {Draw} from "../types/draw";
import {outcomeStatus, serverUrl, shortAddress} from "../utils";

export default function Draws(props: PropsWithChildren<any>) {
  const [draws, setDraws] = useState<Draw[]>()

  useEffect(() => {
    fetch(`${serverUrl()}/api/draws`)
      .then(data => data.json())
      .then(draws => setDraws(draws))

  }, [])

  return (
    <Layout>
      <>
        <Text
          sx={{
            position: "relative",
            "&::after": {
              backgroundColor: "primary",
              position: "absolute",
              content: "''",
              width: "4.8rem",
              height: "2px",
              left: "0px",
              bottom: "-.4rem"
            }
          }}
          variant="heading2"
        >
          Draws
        </Text>

        <Flex
          sx={{
            marginTop: "3.2rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "3.2rem",
            "@media (min-width: 768px)": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }
          }}
        >
          <Flex
            sx={{
              width: "120rem",
              flexDirection: "column",
              alignItems: "center",
              padding: "3.2rem",
              backgroundColor: "background2",
              borderRadius: ".8rem",
              order: 0,
              marginBottom: "3.2rem",
              border: "1px solid transparent",
              transition: "all .125s linear",
              boxShadow: "0px 0px 13px #ff00001a",
              "@media (min-width: 768px)": {
                order: 1,
                marginBottom: 0
              }
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "space-between",
                marginBottom: "1.6rem"
              }}
            >
              {
                draws && draws.length > 0 ? (
                  <table sx={{
                    width: "100%"
                  }}>
                    <thead>
                    <tr>
                      <th align='left'>#</th>
                      <th align='left'>Snatcher</th>
                      <th align='left'>Wallet</th>
                      <th align='left'>Status</th>
                      <th align='left'>Created</th>
                      <th align='left'>Outcome</th>
                    </tr>
                    </thead>
                    <tbody>
                    { draws && draws.map(draw => (
                      <tr key={draw.id}>
                        <td>{draw.id}</td>
                        <td>
                          <Flex sx={{
                            alignItems: "flex-start",
                            justifyContent: "space-between"
                          }}>
                            <img
                              sx={{
                                maxHeight: "40px",
                                border: "1px solid",
                                borderColor: "#fff",
                                borderRadius: "5px",
                                boxShadow: "0 0 2px 2px #ffffff26",
                                marginRight: "10px"
                              }}
                              src={draw.image}
                            />
                            <div sx={{
                              flex: 1
                            }}>
                              <Text sx={{
                                display: "block"
                              }}>{draw.name}</Text>
                              <Text sx={{
                                display: "block",
                                fontSize: "13px"
                              }}>{shortAddress(draw?.mint!)}</Text>
                            </div>
                          </Flex>
                        </td>
                        <td>{shortAddress(draw?.wallet!)}</td>
                        <td>{draw.status}</td>
                        <td>{draw.created_at}</td>
                        <td>{outcomeStatus(draw)}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                ) : (
                  <Flex
                    sx={{
                      justifyContent: "center",
                      alignSelf: "stretch"
                    }}
                  >
                    <Spinner variant="styles.spinnerLarge" />
                  </Flex>
                )
              }
            </Flex>
          </Flex>
        </Flex>
      </>
    </Layout>
  )
}
