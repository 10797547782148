/** @jsxImportSource theme-ui */
import React, { useState } from "react"
import { Button, Container, Flex, Text } from "@theme-ui/components"
import CloseIcon from "./icons/CloseIcon";
import MenuIcon from "./icons/MenuIcon";
import { Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import SettingsIcon from "./icons/SettingsIcon";

export default function Header(){
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)
    const wallet = useWallet()

    return (
        <Flex
            sx={{
                position: "sticky",
                borderColor: "background2",
                top: 0,
                zIndex: 9,
                background: (theme) => theme.colors?.backgroundGradient
            }}
        >
            <Container sx={{
              maxWidth: "120rem"
            }}>
                <Flex
                    sx={{
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                    p="1.6rem"
                >
                    <Link to="/">
                        <Flex as="div" sx={{ alignItems: "center" }}>
                            <Text as="h1" variant="heading">
                                SolUnderworld
                            </Text>
                        </Flex>
                    </Link>
                    <Flex
                        as="nav"
                        sx={{
                            gap: "1.6rem",
                            display: "none",

                            /** Mobile styles when the menu is active */
                            ...(isMobileMenuActive && {
                                display: "flex",
                                position: "fixed",
                                flexDirection: "column",
                                alignItems: "center",
                                top: "0",
                                left: "0",
                                width: "100vw",
                                height: "100vh",
                                padding: "1.6rem",
                                transition:
                                    "opacity 0.125s cubic-bezier(0.175, 0.885, 0.32, 1.275),visibility 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                                backgroundColor: "background",
                                zIndex: 99,

                                "& > a": {
                                    marginBottom: ".8rem"
                                },

                                "&.active": {
                                    visibility: "visible",
                                    opacity: 1
                                }
                            }),

                            /** Desktop styles (reset mobile) */
                            "@media (min-width: 768px)": {
                                display: "flex",
                                flexDirection: "row",
                                width: "auto",
                                height: "auto",
                                padding: 0,
                                position: "relative"
                            }
                        }}
                    >
                        <Button
                            sx={{
                                alignSelf: "flex-end",
                                padding: ".8rem",
                                ...(!isMobileMenuActive && { display: "none" })
                            }}
                            onClick={() => setIsMobileMenuActive(false)}
                        >
                            <CloseIcon />
                        </Button>

                        <Link to="/" sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".4rem",
                            opacity: 0.8
                        }}>
                            Mystery Box
                        </Link>

                        <Link to="/draws" sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".4rem",
                            opacity: 0.8
                        }}>
                            Draws
                        </Link>

                        {wallet.publicKey ? (
                            <Link to="/wallet" sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: ".4rem"
                            }}>
                                My Wallet
                            </Link>
                        ) : null}

                      <Flex
                        sx={{
                          alignSelf: "stretch",
                          justifyContent: "center",
                          alignItems: "center",

                          ".wallet-adapter-dropdown": {
                            display: "flex",
                            justifyContent: "center"
                          },

                          ".wallet-adapter-button[disabled]": {
                            backgroundColor: "#141221 !important",
                            opacity: 0.7
                          }
                        }}
                      >
                        <Flex
                          sx={{
                            justifyContent: "center"
                          }}
                        >
                          {wallet?.publicKey ? (
                            <WalletMultiButton
                              sx={{
                                backgroundColor: "unset",
                                transition: "all .3s linear",
                                color: "heading",
                                lineHeight: "body",
                                fontSize: "1.4rem",
                                padding: "0",
                                height: "unset",
                                alignSelf: "flex-end",

                                "&:hover": {
                                  background: "unset",
                                  backgroundImage: "unset!important",
                                  color: "primary",
                                  cursor: "pointer"
                                }
                              }}
                              startIcon={null}
                            >
                              <SettingsIcon
                                sx={{
                                  transition: "all .125s linear",
                                  "&:hover": {
                                    stroke: "primary"
                                  }
                                }}
                              />
                            </WalletMultiButton>
                          ) : (
                            <WalletMultiButton
                              startIcon={null}
                              sx={{
                                backgroundColor: "unset",
                                transition: "all .3s linear",
                                color: "heading",
                                lineHeight: "body",
                                fontSize: "1.4rem",
                                padding: "0",
                                height: "unset",
                                alignSelf: "flex-end",

                                "&:hover": {
                                  background: "unset",
                                  backgroundImage: "unset!important",
                                  color: "primary",
                                  cursor: "pointer"
                                }
                              }}
                            />
                          )}
                        </Flex>
                      </Flex>

                    </Flex>
                    <Button
                        sx={{
                            padding: ".8rem",
                            "@media(min-width: 768px)": {
                                display: "none"
                            }
                        }}
                        onClick={() => setIsMobileMenuActive(true)}
                    >
                        <MenuIcon />
                    </Button>
                </Flex>
            </Container>
        </Flex>
    )
}
