/** @jsxImportSource theme-ui */
import React, {PropsWithChildren, useMemo} from "react"
import { clusterApiUrl } from '@solana/web3.js';
import {
  ConnectionProvider,
  WalletProvider
} from "@solana/wallet-adapter-react"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
  LedgerWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui"

const SolanaWalletProvider = ({ children }: PropsWithChildren<any>) => {
  const network = process.env.REACT_APP_SOLANA_HTTP_RPC_URL as any

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SolletWalletAdapter({ network }),
      new LedgerWalletAdapter()
    ],
    [network]
  )


  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          {children}
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default SolanaWalletProvider
