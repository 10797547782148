/** @jsxImportSource theme-ui */
import React, {PropsWithChildren, useEffect, useState} from 'react'
import { Button, Flex, Text } from "theme-ui"
import Layout from "../components/layout/Layout"
import {Link, useSearchParams} from "react-router-dom";
import LoadingIcon from "../components/layout/icons/LoadingIcon";
import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {NFT} from "../types/nft";
import {portalWallet, serverUrl} from "../utils";
import {Connection, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID} from "@solana/spl-token";
import {getPusher} from "../lib/pusher";

const pusher = getPusher()

export default function Home(props: PropsWithChildren<any>) {
  const [nfts, setNfts] = useState<NFT[]>()
  const [ channelName, setChannelName ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ searchParams ] = useSearchParams({});
  const [ selectedCollectionItem, setSelectedCollectionItem ] = useState<any>(null)
  const [ status, setStatus ] = useState(" ")
  const { publicKey, signTransaction } = useWallet()
  const { connection } = useConnection()

  useEffect(() => {
    if (!publicKey) {
      return
    }
    fetch(`${serverUrl()}/api/wallet/${publicKey.toString()}`)
      .then(data => data.json())
      .then(data => setNfts(data))

  }, [publicKey])

  useEffect(() => {
    if (searchParams.get('mintAddress') && nfts) {
      setIsLoading(true)
      const selected = nfts.find((NFT) => NFT.address === searchParams.get('mintAddress'))
      if (selected) {
        setSelectedCollectionItem(selected)
      }
      setIsLoading(false)
    }
  }, [searchParams, nfts])

  useEffect(() => {
    return () => {
      if (channelName) {
        pusher.unsubscribe(channelName)
      }
    }
  }, [])

  const handleUnlockButtonClick = (e: any) => {
    e.preventDefault()
    if (isLoading) {
      return
    }
    if (!publicKey) {
      setStatus("Connect your wallet first!")
      return
    }
    if (!selectedCollectionItem) {
      setStatus("No item selected!")
      return
    }

    const createTransaction = async () => {
      setIsLoading(true)
      setStatus("Sending transaction...")

      const mintPk = new PublicKey(selectedCollectionItem?.address)
      const toWalletPk = new PublicKey(portalWallet()!)

      const fromTokenAddress = (
        await PublicKey.findProgramAddress([
          publicKey.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          mintPk.toBuffer()
        ], ASSOCIATED_TOKEN_PROGRAM_ID)
      )[0]

      const recipientTokenAddress = (
        await PublicKey.findProgramAddress([
          toWalletPk.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          mintPk.toBuffer()
        ], ASSOCIATED_TOKEN_PROGRAM_ID)
      )[0]

      const recipientAccountInfo = await connection.getAccountInfo(recipientTokenAddress)

      const transaction = new Transaction({
        feePayer: publicKey
      })

      if (!recipientAccountInfo) {
        transaction.add(
          Token.createAssociatedTokenAccountInstruction(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            mintPk,
            recipientTokenAddress,
            toWalletPk,
            publicKey
          )
        )
      }

      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: toWalletPk,
          lamports: LAMPORTS_PER_SOL * 0.1
        }),
      )

      transaction.add(
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          fromTokenAddress,
          recipientTokenAddress,
          publicKey,
          [],
          1
        )
      )

      const { lastValidBlockHeight, blockhash } = await connection.getLatestBlockhash('finalized')

      transaction.recentBlockhash = blockhash

      const signedTransaction = await signTransaction?.(transaction)
      const serializedTx = await signedTransaction?.serialize()
      const tx = serializedTx?.toString("hex")

      const result = await fetch(`${serverUrl()}/api/solana`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tx,
          lastValidBlockHeight
        })
      })
      const data = await result.json()
      if (data.error) {
        throw new Error(data.message)
      }

      return data
    }

    createTransaction()
      .then((data) => {
        const signature = data.signature
        setChannelName(signature)

        const channel = pusher.subscribe(signature);
        channel.bind('success', function(data: { message: string }) {
          setIsLoading(false)
          setStatus(data.message)
          setSelectedCollectionItem(null)
        });
        channel.bind('fail', function(data: { message: string }) {
          setIsLoading(false)
          setStatus(data.message)
        });
      })
      .catch((e) => {
        setIsLoading(false)
        setStatus(e.message)
      })
  }

  return (
    <Layout>
      <>
        <Text
          sx={{
            position: "relative",
            "&::after": {
              backgroundColor: "primary",
              position: "absolute",
              content: "''",
              width: "4.8rem",
              height: "2px",
              left: "0px",
              bottom: "-.4rem"
            }
          }}
          variant="heading2"
        >
          Mystery Box
        </Text>
        <Flex
          sx={{
            marginTop: "3.2rem",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "3.2rem",
            "@media (min-width: 768px)": {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start"
            }
          }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              order: 1,
              alignSelf: "stretch",

              "@media (min-width: 768px)": {
                order: 0
              }
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                position: "relative"
              }}
            >
              <img
                sx={{
                  maxHeight: "280px",
                  border: ".2rem solid",
                  borderColor: "#fff",
                  borderRadius: "1.6rem",
                  boxShadow: "0 0 5px 5px #ffffff26"
                }}
                src={ selectedCollectionItem ? selectedCollectionItem.image : `/images/mystery-box2.png` }
              />
              {!selectedCollectionItem ? (
                <img
                  sx={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    margin: "auto"
                  }}
                  src="/images/mystery-box.png"
                />
              ) : null}
            </Flex>

            <Text
              sx={{
                display: "flex",
                padding: "0 1.6rem"
              }}
              mt="1.6rem"
              variant="small"
            >
              {isLoading ? (
                <LoadingIcon
                  sx={{
                    marginRight: ".8rem",
                    marginTop: ".1rem",
                    marginLeft: "auto"
                  }}
                  size="1.6rem"
                />
              ) : null}
              <Text
                sx={{
                  textAlign: isLoading ? "initial" : "center"
                }}
              >
                {status || " "}
              </Text>
            </Text>
            <Button
              sx={{
                marginTop: "1.6rem",
                marginBottom: "1.6rem",

                "@media (min-width: 768px)": {
                  marginBottom: 0
                }
              }}
              variant="special"
              disabled={!selectedCollectionItem || isLoading}
              onClick={handleUnlockButtonClick}
            >
              Unlock!
            </Button>

            <Flex
              sx={{
                alignItems: "flex-end",
                alignSelf: "flex-end",
                marginTop: "auto"
              }}
            >
              <img
                sx={{
                  maxWidth: "9.6rem",
                  transform: "rotate(-7deg)"
                }}
                src="/images/skull.png"
              />
              <img
                sx={{
                  maxWidth: "9.6rem"
                }}
                src="/images/candelabra_skull.png"
              />
            </Flex>
          </Flex>

          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              padding: "3.2rem",
              backgroundColor: "background2",
              borderRadius: ".8rem",
              order: 0,
              marginBottom: "3.2rem",
              border: "1px solid transparent",
              transition: "all .125s linear",
              boxShadow: "0px 0px 13px #ff00001a",
              "@media (min-width: 768px)": {
                order: 1,
                maxWidth: "88rem",
                marginBottom: 0
              }
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                alignSelf: "stretch",
                justifyContent: "space-between",
                marginBottom: "1.6rem"
              }}
            >
              <Text as="h3" variant="heading3">
                Feeling lucky today?
              </Text>
              <img
                sx={{
                  maxWidth: "6.4rem",
                  transform: "matrix(-1, 0, 0, 1, 0, 0)"
                }}
                src="/images/head2.png"
              />
            </Flex>
            <Text>
              A mysterious box appeared, and rumors started to rise on Solana’s
              Underworld.
              <br />
              <br />
              Once opened, some say the Mystery Box can bring fortune and fame to
              the bravest and lucky Reapers.
              <br />
              <br />
              While the result varies based on luck, courage is also necessary. Some
              Reapers may be sacrificed for the fortune to come.
              <br />
              <Text
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: ".8rem 0",
                  userSelect: "none"
                }}
              >
                &bull;
              </Text>
              Once willing to sacrifice a Reaper, the Mystery Box can be unlocked.
              One should expect the following results:
              <br />
              <table sx={{
                marginTop: "20px",
                td: {
                  padding: "0px",
                  paddingRight: "20px"
                }
              }}>
                <tbody>
                <tr><td>1.</td><td>Lost</td><td>20.50%</td></tr>
                <tr><td>2.</td><td>Returned</td><td>45.00%</td></tr>
                <tr><td>3.</td><td>Returned + SolSnatcher</td><td>19.50%</td></tr>
                <tr><td>4.</td><td>Returned + 0.30 SOL</td><td>10.00%</td></tr>
                <tr><td>5.</td><td>Returned + 0.75 SOL</td><td>3.00%</td></tr>
                <tr><td>6.</td><td>Returned + 1.50 SOL</td><td>1.00%</td></tr>
                <tr><td>7.</td><td>Returned + 3.00 SOL</td><td>0.90%</td></tr>
                <tr><td>8.</td><td>Returned + 10.00 SOL</td><td>0.10%</td></tr>
                </tbody>
              </table>
            </Text>
            <Text
              sx={{
                padding: "0 3.2rem",
                textAlign: "center"
              }}
              variant="heading4"
              mt="2.4rem"
            >
              I&apos;m sure you're dying to see <br /> what's hidden under this
              spell...
            </Text>
            <Text
              sx={{
                padding: "0 3.2rem",
                textAlign: "center"
              }}
              variant="heading4"
              mt="2.4rem"
            >
              Portal fee is 0.1 SOL
            </Text>
            <Link to="/wallet">
              <Button
                sx={{
                  boxShadow: (theme) => `0px 0px 5 ${theme.colors?.primary}`,
                  "&:hover": {
                    boxShadow: (theme) => `0px 0px 13px ${theme.colors?.primary}`
                  }
                }}
                mt="1.6rem"
              >
                I&apos;m feeling lucky!
              </Button>
            </Link>
          </Flex>
        </Flex>
      </>
    </Layout>
  )
}
