import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Wallet from "./pages/wallet";
import Draws from "./pages/draws";
import Home from "./pages/home";
import { ThemeProvider } from "theme-ui"
import defaultTheme from "./styles/theme"
import "normalize.css/normalize.css"
import "@solana/wallet-adapter-react-ui/styles.css"
import SolanaWalletProvider from "./components/providers/SolanaWalletProvider"

function App() {
  return (
    <ThemeProvider theme={defaultTheme as any}>
      <SolanaWalletProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/wallet" element={<Wallet />}/>
            <Route path="/draws" element={<Draws />}/>
            <Route path="/" element={<Home />}/>
          </Routes>
        </BrowserRouter>
      </SolanaWalletProvider>
    </ThemeProvider>
  );
}

export default App;
