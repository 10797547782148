/** @jsxImportSource theme-ui */
import React from "react"

export default function MenuIcon() {
    return (
        <svg
            sx={{
                stroke: "text"
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
        >
            <path stroke="none" d="M0 0h24v24H0z"/>
            <path d="M4 6L20 6"/>
            <path d="M4 12L20 12"/>
            <path d="M4 18L20 18"/>
        </svg>
    )
}
