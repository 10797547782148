/** @jsxImportSource theme-ui */
import React, {useEffect, useState} from "react"
import { Flex, Spinner, Text } from "theme-ui"
import { useWallet } from "@solana/wallet-adapter-react"
import { Link } from "react-router-dom";
import Layout from "../components/layout/Layout";
import LinkIcon from "../components/layout/icons/LinkIcon";
import CollectionItem from "../components/layout/CollectionItem";
import {NFT} from "../types/nft";
import {serverUrl} from "../utils";

export default function Wallet() {
  const { publicKey } = useWallet()
  const [nfts, setNfts] = useState<NFT[]>()

  useEffect(() => {
    if (!publicKey) {
      return
    }

    fetch(`${serverUrl()}/api/wallet/${publicKey.toString()}`)
      .then(data => data.json())
      .then(data => setNfts(data))

  }, [publicKey])


  return (
    <Layout>
      <>
        <Text mb="1.6rem" variant="heading2">
          Wallet overview
        </Text>
        <Flex
          sx={{
            flexDirection: "column",
            gap: ".8rem"
          }}
          mb="3.2rem"
        >
          {publicKey ? (
            <>
              <Flex
                sx={{
                  alignItems: "center",
                  gap: ".4rem"
                }}
              >
                <LinkIcon />
                <Text
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                >
                  {publicKey.toString()}
                </Text>
              </Flex>
            </>
          ) : null}
        </Flex>
        {publicKey ? (
          <Text variant="heading2" mb="1.6rem">
            SolSnatchers collection
          </Text>
        ) : null}
        {!nfts && !publicKey ? (
          /** No NFTs and no public key, means wallet is not connected */
          <Flex
            sx={{
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <Text>Connect your wallet first.</Text>
          </Flex>
        ) : null}
        {nfts ? (
            nfts.length ? (
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "1.6rem",

                  "@media (min-width: 768px)": {
                    gridTemplateColumns:
                      nfts.length > 9
                        ? "1fr 1fr 1fr 1fr 1fr"
                        : "1fr 1fr 1fr 1fr"
                  }
                }}
              >
                {nfts.map((item) => {
                  return (
                    <CollectionItem
                      key={item.address}
                      item={item}
                      additionalOptions={
                        <Link to={`/?mintAddress=${item.address}`} sx={{
                          whiteSpace: "nowrap"
                        }}>
                          Load the Mystery Box
                        </Link>
                      }
                    />
                  )
                })}
              </div>
            ) : (
              /** NFTs fetched but array is empty, means current wallet has no SolSnatcher. */
              <Flex
                sx={{
                  justifyContent: "center",
                  alignSelf: "stretch"
                }}
              >
                <Text>There are no Solsnatchers on your wallet.</Text>
              </Flex>
            )
          ) : /** No NFTs and public key, means it is loading */
          publicKey ? (
            <Flex
              sx={{
                justifyContent: "center",
                alignSelf: "stretch"
              }}
            >
              <Spinner variant="styles.spinnerLarge" />
            </Flex>
          ) : null}

      </>
    </Layout>
  )
}
