import base from "./preset-base"

export const getGradient = (rgb) => {
  /**
   * Split RGB. Example: rgb(226, 217, 211)
   * Then extract only numbers
   */
  const splitted = rgb.split(",").map((raw) => raw.replace(/\D/g, ""))

  return `linear-gradient(225deg, ${rgb} 0%, rgba(${splitted[0]}, ${splitted[1]}, ${splitted[2]}, 0.7) 50%, rgba(${splitted[0]}, ${splitted[1]}, ${splitted[2]}, 0.5) 100%)`
}

const theme = {
  ...base,
  colors: {
    background: "rgb(26, 26, 38)",
    text: "rgb(150, 154, 178)",
    primary: "rgb(221, 64, 67)",
    primaryGradient: getGradient("rgb(221, 64, 67)"),
    heading: "rgb(225, 225, 225)",
    background2: "#212334",
    backgroundGradient: getGradient("rgb(26, 26, 38)")
    // modes: {
    //   light: {
    //     background: "rgb(150, 154, 178)",
    //     text: "rgb(26, 26, 38)",
    //     primary: "rgb(221, 64, 67)",
    //     heading: "rgb(26, 26, 38)",
    //     background2: "#212334",
    //     backgroundGradient: getGradient("rgb(150, 154, 178)")
    //   }
    // }
  },

  sizes: {
    container: "80rem"
  },
  config: {
    useLocalStorage: true
  },

  fonts: {
    heading:
      'Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'
  },

  buttons: {
    primary: {
      display: "flex",
      color: "#fff",
      background: (theme) => theme.colors?.primaryGradient,
      border: "1px solid transparent",
      transition: "all .125s linear",
      alignItems: "center",
      borderColor: "primary",
      opacity: 1,
      fontWeight: 500,

      "&:not(:disabled):hover": {
        bg: "background",
        borderColor: "primary",
        cursor: "pointer",
        opacity: 0.7
      },

      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3
      }
    },
    special: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "background2",
      transform: "perspective(1px) translateZ(0)",
      position: "relative",
      background: "linear-gradient(45deg, white, grey)",
      borderRadius: ".4rem",
      boxShadow: "0 0 5px 2px #00000054",
      fontSize: "1.6rem",
      padding: "1.2rem 3.2rem",
      textTransform: "capitalize",
      fontWeight: 500,
      textAlign: "center",
      transition: "all .125s linear",

      "&:not(:disabled):hover": {
        background: "linear-gradient(225deg, white, grey)",
        cursor: "pointer"
      },

      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3
      }
    },
    resetted: {
      display: "flex",
      background: "none" /*essential*/,
      border: "none" /*essential*/,
      padding: "0" /*essential*/,
      font: "inherit" /*important as otherwise the text will look slightly different*/,
      color:
        "inherit" /*if you want the span the same colour as the rest of the sentence*/,
      cursor:
        "pointer" /*make sure you add this, but if you really want it to behave like a span you would leave this out*/,
      transition: "all .125s linear",

      "&:not(:disabled):hover": {
        cursor: "pointer",
        opacity: 0.7
      },

      "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.3
      }
    }
  },

  lineHeights: { body: 1.45 },

  text: {
    heading: {
      color: "heading",
      lineHeight: "body",
      fontSize: "2.2rem",
      fontFamily:
        'Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 900
    },
    heading2: {
      color: "heading",
      lineHeight: "body",
      fontSize: "1.9rem",
      fontFamily: "heading",
      fontWeight: 600
    },
    heading3: {
      color: "heading",
      lineHeight: "body",
      fontSize: "1.7rem",
      fontWeight: 600
    },
    heading4: {
      color: "heading",
      lineHeight: "body",
      fontSize: "1.6rem",
      fontWeight: 600
    },
    base: {
      color: "text",
      lineHeight: "body",
      fontSize: "1.4rem"
    },
    small: {
      color: "text",
      lineHeight: "body",
      fontSize: "1.2rem"
    }
  },

  styles: {
    ...base.styles,

    root: {
      ...base.styles?.root,
      fontSize: "62.5%",
      minHeight: "100vh",

      body: {
        /** Default text styles */
        fontSize: "1.4rem",
        fontFamily:
          'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        lineHeight: 1.45,
        minHeight: "100vh",
        color: "text"
      },

      img: {
        maxWidth: "100%",
        height: "auto"
      },

      p: {
        margin: 0
      },

      a: {
        transition: "all .125s linear",

        "&:hover": {
          cursor: "pointer",
          color: "primary"
        },

        "&:-webkit-any-link": {
          color: "text",
          textDecoration: "none",

          "&:hover": {
            cursor: "pointer",
            color: "primary"
          }
        }
      },

      table: {
        th: {
          padding: "5px"
        },
        td: {
          padding: "5px"
        }
      }
    },

    spinnerSmall: {
      size: "1.2rem!important"
    }
  }
}

export default theme
